/* ************************
   03.6: Coming Soon
   ********************* */
.content-coming-soon {
    @extend .content404;
    h1 {
        font-size: 60px;
        line-height: 1.1;
        @include mobileMd {
            font-size: 40px;
        }
    }
}