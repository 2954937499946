/* ************************
   03.2: Banner
   ********************* */
.banner {
    padding-top: 140px;
    position: relative;
    overflow: hidden;
    background-size: contain;
    background-position: bottom center;
    background-repeat: no-repeat;
    @include tab {
        padding-top: 100px;
    }
    .banner_content {
        margin-bottom: 100px;
        h1 {
            font-size: 60px;
            line-height: 1.37;
            animation-delay: 0s;
            margin-top: -16px;
            @include mobileMd {
                font-size: 40px;
                margin-top: -10px;
            }
        }
        p {
            line-height: 1.67;
            max-width: 780px;
            margin: 20px auto 40px;
            animation-delay: .5s;
        }
        .btn {
            animation-delay: .7s;
        }
    }
    .banner-images {
        img {
            width: auto !important;
            margin: 0 auto;
        }
        .search-icon-box {
            right: 30px;
            top: -20px;
            width: 150px;
            height: 150px;
            display: flex;
            align-items: center;
            justify-content: center;
            z-index: 3;
            @include tab {
                right: -40px;
                top: -40px;
            }
            img {
                animation: matrix 5s linear infinite;
            }
        }
        .banner-icon {
            position: absolute;
            z-index: -1;
            animation-duration: 5s;
            &.icon-1 {
                top: -20px;
                right: 65px;
                @include tab {
                    top: -30px;
                    right: 25px;
                }
                @include mobileMd {
                    top: -30px;
                    right: 65px;
                }
                @include mobileSm {
                    right: 35px;
                }
                @include mobileXs {
                    right: 20px;
                }
            }
            &.icon-2 {
                left: 120px;
                top: 50px;
                z-index: 1;
                @include tab {
                    left: 60px;
                    top: 40px;
                }
                @include mobileMd {
                    left: 125px;
                }
                @include mobileSm {
                    left: 90px;
                }
                @include mobileXs {
                    left: 70px;
                }
            }
            &.icon-3 {
                left: 90px;
                top: 20px;
                @include tab {
                    left: 40px;
                }
                @include mobileMd {
                    left: 80px;
                }
                @include mobileSm {
                    left: 50px;
                }
                @include mobileXs {
                    left: 40px;
                }
            }
        }
    }
    .banner_slider {
        .owl-dots {
            .owl-dot {
                display: block;
                margin: 7px auto;
            }
        }
    }
    .slider-counter {
        position: absolute;
        left: 50px;
        top: 50%;
        transform: translateY(-50%);
        font-size: 24px;
        font-weight: $bold;
        @extend .alegreya;
        @extend .c2;
        span {
            font-size: 20px;
            @extend .c1;
        }
    }
}