/* ************************
   03.1: Header
   ********************* */
.header {
    position: relative;
    min-height: 100px;
    .logo-holder {
        @extend .trans4;
    }
    .header-main {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        padding: 30px 140px;
        @extend .trans3;
        @include medium {
            padding: 30px 80px;
        }
        @include tab {
            padding: 0;
        }
        &.sticky {
            padding-top: 0;
            padding-bottom: 0;
            position: fixed;
            width: 100%;
            z-index: 999;
            left: 0;
            top: 0;
            .row {
                background-color: $white;
                box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
                margin-top: 20px;
                @include mobileSm {
                    margin-top: 0;
                }
            }
        }
        .main-menu-wrapper {
            min-height: 100px;
            user-select: none;
            position: relative;
            z-index: 3;
            padding-left: 25px;
            padding-right: 25px;
            @extend .trans3;
            @include tab {
                margin-left: 25px;
                margin-right: 25px; 
            }
            @include mobileLg {
                padding-left: 0;
                padding-right: 0;
            }
            @include mobileSm {
                margin-left: -15px;
                margin-right: -15px;
            }
            &:after {
                content: '';
                position: absolute;
                display: block;
                width: 0;
                height: 100%;
                top: 0;
                left: 0;
                background: #ffffff;
                box-shadow: 0 6px 16px rgba($color: #000000, $alpha: .06);
                z-index: -1;
                @extend .trans3;
            }
            &.show {
                &:after {
                    width: 100%;
                }
            }
        }
        .menu-trigger,
        #menu-button {
            display: inline-flex;
            height: 25px;
            width: 30px;
            align-items: center;
            cursor: pointer;
            margin-right: 20px;
            span {
                position: relative;
                width: 22px;
                &,
                &:before,
                &:after {
                    display: block;
                    height: 2px;
                    border-radius: 2px;
                    @extend .c1-bg;
                    @extend .trans3;
                }
                &:before,
                &:after {
                    position: absolute;
                    content: '';
                }   
                &::before {
                    top: -7px;
                    width: 27px;
                }
                &::after {
                    top:  7px;
                    width: 16px;
                }
            }
            &:hover {
                span,
                span:before,
                span:after {
                    width: 27px;
                }
            }
            &.active,
            &.menu-opened {
                span {
                   background-color: transparent;
                    &:before {
                        width: 25px;
                        transform: translateY(7px) rotate(45deg);
                    }
                    &:after {
                        width: 25px;
                        transform: translateY(-7px) rotate(-45deg);
                    } 
                }
            }
        }
        .menu-trigger {
            @include tab {
                display: none;
            }
        }
  
        .main-menu {
            #menu-button {
                display: none;
                font-size: 28px;
                cursor: pointer;
                @extend .c1;
                @include tab {
                    display: inline-flex;
                }
                span {
                    &,
                    &:before,
                    &:after {
                        width: 25px;
                    }
                }
            }
            .nav-wrapper {
                position: relative;
                display: none !important;
                transition: .7s cubic-bezier(.07,.98,0,.96);
                left: -200px;
                @include tab {
                    position: static;
                    left: 0;
                    display: block !important;
                }
                &.active {
                    display: block !important;
                    left: 0;
                    width: max-content;
                }
            }
            .nav {
                li {
                    position: relative;
                    &.current-menu-item,
                    &.current-menu-parent {
                        > a {
                            @extend .c2;
                        }
                    }
                    a {
                        display: block;
                        line-height: 1;
                        position: relative;
                        z-index: 1;
                        text-transform: capitalize;
                    }
                    ul {
                        position: absolute;
                        left: 0;
                        top: -2000px;
                        opacity: 0;
                        visibility: hidden;
                        background-color: $white;
                        width: 210px;
                        padding: 15px 0;
                        box-shadow: 0 5px 20px rgba(0, 0, 0, 0.05);
                        transform: translate3d(0, -5px, 0);
                        transition: transform 0.2s ease-out;
                        z-index: 9;
                        li {
                            display: block;
                            width: 100%;
                            line-height: 1;
                            font-size: 16px;
                            &:hover {
                                > a {
                                    margin-left: 5px;
                                    @extend .c2;
                                }
                            }
                            a {
                                padding: 10px 30px;
                                @extend .c1;
                            }
                            ul {
                                top: 0;
                                left: calc(100% + 20px);
                                @extend .trans2;
                            }
                            &:hover {
                                > ul {
                                    opacity: 1;
                                    visibility: visible;
                                    top: 0;
                                    left: 100%;
                                }
                            
                            }
                        }
                    }
                }
                > li {
                    display: inline-block;
                    padding: 0 15px;
                    > a {
                        line-height: 100px;
                        position: relative;
                        font-weight: $semi-bold;
                    }
                    &:hover {
                        > ul {
                            opacity: 1;
                            visibility: visible;
                            top: 100%;
                            transform: translate3d(0, 0, 0);
                        }
                    }
                }
            }
            .offcanvas-trigger {
                display: inline-block;
                width: 30px;
                cursor: pointer;
                span {
                    display: block;
                    height: 3px;
                    @extend .trans3;
                    &:nth-child(2) {
                        margin: 5px 0;
                        margin-left: 5px;
                    }
                    &:nth-child(3) {
                        margin-left: 10px;
                    }
                }
                &:hover {
                    span {
                        margin-left: 0 !important;
                    }
                }
            }
        }
        
    }
}


/* Mobile Menu */
@include tab {
    .header {
        .header-main {
            .row {
                position: relative;
                div:nth-child(1) {
                    position: static;
                }
         
                .main-menu {
                    text-align: right;
                    position: initial;
                    #menu-button {
                        display: inline-flex;
                        height: 25px;
                        width: 25px;
                        align-items: center;
                        justify-content: center;
                    }
                    ul.nav {
                        position: absolute;
                        top: 100%;
                        left: 50%;
                        right: 50%;
                        width: 100%;
                        background: #fff;
                        box-shadow: 0 5px 10px rgba(0, 0, 0, 0.05);
                        max-height: 350px;
                        overflow-y: auto;
                        padding: 15px 20px;
                        max-width: 800px;
                        -webkit-transform: translateX(-50%);
                        transform: translateX(-50%);
                        z-index: 99999;
                        margin-right: 0;
                        > li {
                            padding: 0;
                        }
                        ul {
                            position: relative;
                            left: 0;
                            right: auto;
                            top: 0;
                            width: 100%;
                            display: none;
                            padding: 0;
                            opacity: 1;
                            visibility: visible;
                            text-align: left;
                            z-index: 99999;
                            box-shadow: none;
                            transform: translate3d(0, 0, 0);
                            li {
                                overflow: hidden;
                                &:hover {
                                    > a {
                                        margin-left: 0;
                                    }
                                }
                            }
                            ul {
                                position: relative;
                                margin-left: 30px;
                                left: 0 !important;
                                opacity: 1;
                                visibility: visible;
                            }
                        }
                    }

                    ul {
                        text-align: left;
                        li {
                            display: block;
                            width: 100%;
                            a {
                                line-height: 1 !important;
                                padding: 12px 15px;
                                color: $c1;
                            }
                            &.current-menu-parent > a,
                            &.current-menu-item > a {
                                color: $c2 !important;
                            }
                        }
                    }
                    .submenu-button {
                        display: block;
                        position: absolute;
                        z-index: 9;
                        top: 0;
                        right: 15px;
                        width: 40px;
                        height: inherit;
                        line-height: 40px;
                        text-align: center;
                        color: initial;
                        &:before {
                            @include fa('\f105');
                        }
                        &.submenu-opened:before {
                            content: '\f106';
                            color: $c2;
                        }
                            
                    }
                } 
            }
        }
    }
}



