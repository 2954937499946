@charset "UTF-8";
/*
	*******************
	Template name:  SeoBiz
	Version:        1.0
	Author:         ThemeLooks
	Author url:     http://themelooks.com

	NOTE:
	-----
	Please DO NOT EDIT THIS CSS, you may need to use "custom.css" file for writing your custom css.
	We may release future updates so it will overwrite this file. it's better and safer to use "custom.css".

    ******** CSS INDEX ********
    01. Base
        1.1 Reset 
        1.2 Margin/Padding
        1.3 color
    02. Components
        2.1 Common Classes
        2.2 Animations
        2.3 Preloader
        2.4 Button
        2.5 Section Title
        2.6 Page Title
        2.7 Widgets
        2.8 Back to Top
    03. Layout
        3.1 Header 
        3.2 Banner
        3.3 Blog
        3.4 Footer
        3.5 404
        3.6 Coming Soon
    04. Sections
        4.1 Service
        4.2 Pricing
        4.3 Testimonial
        4.4 Contact

    ********************/
/* ************************
   01.1: Reset
   ********************* */
* {
  outline: none !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
}

::selection {
  text-shadow: none;
  color: #ffffff;
}

::placeholder {
  color: #5E5E5E !important;
  opacity: 1 !important;
}

iframe {
  max-width: 100%;
}

button:focus,
input:focus,
select:focus,
textarea:focus {
  outline: none !important;
}

button,
button[type="submit"],
input[type="submit"] {
  border: none;
  background-color: transparent;
  cursor: pointer;
  -webkit-appearance: button;
  -moz-appearance: button;
}

select {
  width: 100%;
  max-width: 100%;
  color: #5E5E5E;
}

textarea {
  resize: none;
}

img {
  max-width: 100%;
  height: auto;
}

a,
a:hover,
a:active,
a:focus,
input,
input:hover,
input:focus,
input:active,
select,
textarea {
  text-decoration: none;
  outline: none !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  padding: 0;
  margin: 0;
  -ms-word-wrap: break-word;
  word-wrap: break-word;
  font-weight: 700;
  line-height: 1;
}

h1 span,
h2 span,
h3 span,
h4 span,
h5 span,
h6 span {
  color: #FB8579;
}

p:last-child {
  margin: 0;
}

h1 {
  font-size: 40px;
}

h2 {
  font-size: 34px;
}

h3 {
  font-size: 24px;
}

h4 {
  font-size: 18px;
}

h5 {
  font-size: 16px;
}

h6 {
  font-size: 14px;
}

table {
  width: 100%;
  max-width: 100%;
}

table th,
table td {
  border: 1px solid #5E5E5E;
}

pre {
  border: 1px solid;
  border-radius: 4px;
  font-size: 16px;
  line-height: 1.6;
  margin-bottom: 1.6em;
  max-width: 100%;
  overflow: auto;
  padding: 0.8em 1.6em;
}

code {
  background-color: rgba(27, 31, 35, 0.05);
  border-radius: 3px;
  font-size: 85%;
  margin: 0;
  padding: .2em .4em;
}

body {
  overflow-x: hidden !important;
  font-size: 18px;
  line-height: 1.625;
  color: #5E5E5E;
}

/* ************************
   01.2: Padding/Margin
   ********************* */
.pt-0 {
  padding-top: 0px;
}

.pt-10 {
  padding-top: 10px;
}

.pt-20 {
  padding-top: 20px;
}

.pt-30 {
  padding-top: 30px;
}

.pt-40 {
  padding-top: 40px;
}

.pt-50 {
  padding-top: 50px;
}

.pt-60 {
  padding-top: 60px;
}

.pt-70 {
  padding-top: 70px;
}

.pt-80 {
  padding-top: 80px;
}

.pt-90 {
  padding-top: 90px;
}

.pt-100 {
  padding-top: 100px;
}

.pt-110 {
  padding-top: 110px;
}

.pt-120 {
  padding-top: 120px;
}

.pt-130 {
  padding-top: 130px;
}

.pt-140 {
  padding-top: 140px;
}

.pt-150 {
  padding-top: 150px;
}

.pb-0 {
  padding-bottom: 0px;
}

.pb-10 {
  padding-bottom: 10px;
}

.pb-20 {
  padding-bottom: 20px;
}

.pb-30 {
  padding-bottom: 30px;
}

.pb-40 {
  padding-bottom: 40px;
}

.pb-50 {
  padding-bottom: 50px;
}

.pb-60 {
  padding-bottom: 60px;
}

.pb-70 {
  padding-bottom: 70px;
}

.pb-80 {
  padding-bottom: 80px;
}

.pb-90 {
  padding-bottom: 90px;
}

.pb-100 {
  padding-bottom: 100px;
}

.pb-110 {
  padding-bottom: 110px;
}

.pb-120 {
  padding-bottom: 120px;
}

.pb-130 {
  padding-bottom: 130px;
}

.pb-140 {
  padding-bottom: 140px;
}

.pb-150 {
  padding-bottom: 150px;
}

.mt-0 {
  margin-top: 0px;
}

.mt-10 {
  margin-top: 10px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-30 {
  margin-top: 30px;
}

.mt-40 {
  margin-top: 40px;
}

.mt-50 {
  margin-top: 50px;
}

.mt-60 {
  margin-top: 60px;
}

.mt-70 {
  margin-top: 70px;
}

.mt-80 {
  margin-top: 80px;
}

.mt-90 {
  margin-top: 90px;
}

.mt-100 {
  margin-top: 100px;
}

.mt-110 {
  margin-top: 110px;
}

.mt-120 {
  margin-top: 120px;
}

.mt-130 {
  margin-top: 130px;
}

.mt-140 {
  margin-top: 140px;
}

.mt-150 {
  margin-top: 150px;
}

.mb-0 {
  margin-bottom: 0px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-30 {
  margin-bottom: 30px;
}

.mb-40 {
  margin-bottom: 40px;
}

.mb-50 {
  margin-bottom: 50px;
}

.mb-60 {
  margin-bottom: 60px;
}

.mb-70 {
  margin-bottom: 70px;
}

.mb-80 {
  margin-bottom: 80px;
}

.mb-90 {
  margin-bottom: 90px;
}

.mb-100 {
  margin-bottom: 100px;
}

.mb-110 {
  margin-bottom: 110px;
}

.mb-120 {
  margin-bottom: 120px;
}

.mb-130 {
  margin-bottom: 130px;
}

.mb-140 {
  margin-bottom: 140px;
}

.mb-150 {
  margin-bottom: 150px;
}

.pt-250 {
  padding-top: 250px;
}

@media only screen and (max-width: 991px) {
  .pt-120 {
    padding-top: 80px;
  }
  .pb-120 {
    padding-bottom: 80px;
  }
  .pb-90 {
    padding-bottom: 50px;
  }
  .pb-70 {
    padding-bottom: 30px;
  }
}

@media only screen and (max-width: 991px) {
  .pt-140 {
    padding-top: 100px;
  }
  .pb-140 {
    padding-bottom: 100px;
  }
  .pb-110 {
    padding-bottom: 70px;
  }
  .pb-90 {
    padding-bottom: 40px;
  }
}

/* ************************
   01.3: Color
   ********************* */
.c1, a, h1,
h2,
h3,
h4,
h5,
h6, blockquote cite, .btn, .page-title ul li:not(:last-child), .header .header-main .main-menu #menu-button, .header .header-main .main-menu .nav li ul li a, .banner .slider-counter span {
  color: #00217A;
}

.c1-bg, ::selection, .owl-carousel button.owl-dot, .title-border span:first-child, .header .header-main .menu-trigger span, .header .header-main .menu-trigger span:before, .header .header-main .menu-trigger span:after,
.header .header-main #menu-button span,
.header .header-main #menu-button span:before,
.header .header-main #menu-button span:after {
  background-color: #00217A;
}

.c1-bo {
  border-color: #00217A !important;
}

.c2, a:hover, .list-check li:after, blockquote, .btn-inline, .page-title ul li, .widget.widget_service .service-list li a:hover, .widget.widget_service .service-list li a:hover i,
.widget.widget_service .service-list li a:hover svg *, .widget.widget_recent_entries .single-post .post-content h5 a:hover, .widget.widget_tag_cloud .tagcloud a:hover, .header .header-main .main-menu .nav li.current-menu-item > a, .header .header-main .main-menu .nav li.current-menu-parent > a, .header .header-main .main-menu .nav li ul li:hover > a, .banner .slider-counter, .blog-details .post-meta li a:hover, .blog-details .post-tags li a:hover, .footer a:hover, .content404 a, .content-coming-soon a, .single-pricing-table.active .btn, .single-contact-info p span {
  color: #FB8579;
}

.c2-bg, .owl-carousel button.owl-dot.active, .theme-input-group button, .pagination li a:hover, .pagination li a.active,
.pagination li span:hover,
.pagination li span.active, .title-border span, .btn-inline:after, .back-to-top, .blog-details .single-post-pagination .pagination-image:after, .footer .title-border span:first-child, .single-pricing-table.active {
  background-color: #FB8579;
}

.c2-bo, .border-effect:before, .btn:before, .single-service:before, .border-effect:after, .btn:after, .single-service:after {
  border-color: #FB8579 !important;
}

.text-color, .search-form .theme-input-group input, .widget.widget_search .theme-input-group input, .widget.widget_newsletter.style--two .theme-input-group input, .widget.widget_service .service-list li a, .widget.widget_recent_entries .single-post .post-content h5 a, .widget.widget_tag_cloud .tagcloud a, .single-blog-item .blog-content .posted, .blog-details .post-meta li a, .blog-details .post-tags li a, .blog-details .single-post-pagination .posted-on, .blog-details .single-post-pagination h5 {
  color: #5E5E5E;
}

.text-color-bg {
  background-color: #5E5E5E;
}

.text-color-bo {
  border-color: #5E5E5E !important;
}

/* ************************
   02.1: Common Classes
   ********************* */
/* Transitions */
.trans2, .theme-input-group button, .header .header-main .main-menu .nav li ul li ul {
  -webkit-transition: 0.2s linear;
  transition: 0.2s linear;
}

.trans3, a, .owl-carousel button.owl-dot, .theme-input-style, .btn, .btn-inline:after, .widget.widget_service .service-list li img,
.widget.widget_service .service-list li svg,
.widget.widget_service .service-list li i, .header .header-main, .header .header-main .main-menu-wrapper, .header .header-main .main-menu-wrapper:after, .header .header-main .menu-trigger span, .header .header-main .menu-trigger span:before, .header .header-main .menu-trigger span:after,
.header .header-main #menu-button span,
.header .header-main #menu-button span:before,
.header .header-main #menu-button span:after, .header .header-main .main-menu .offcanvas-trigger span, .blog-details .single-post-pagination .pagination-image i, .blog-details .single-post-pagination .pagination-image:after, .brand-logo .single-brand-logo img {
  -webkit-transition: 0.3s linear;
  transition: 0.3s linear;
}

.trans4, .header .logo-holder {
  -webkit-transition: 0.4s linear;
  transition: 0.4s linear;
}

/* Font Family */
h1,
h2,
h3,
h4,
h5,
h6,
.alegreya,
.pagination li a,
.pagination li span,
blockquote,
.page-title ul li,
.widget.widget_service .service-list,
.banner .slider-counter {
  font-family: "Alegreya", serif;
}

body,
.sourceSans,
blockquote cite,
.btn {
  font-family: "Source Sans Pro", sans-serif;
}

/* Text White */
.text-white *, .single-pricing-table.active *,
.text-white h1,
.single-pricing-table.active h1,
.text-white h2,
.single-pricing-table.active h2,
.text-white h3,
.single-pricing-table.active h3,
.text-white h4,
.single-pricing-table.active h4,
.text-white h5,
.single-pricing-table.active h5,
.text-white h6,
.single-pricing-table.active h6 {
  color: #ffffff;
}

/* Border Radius */
.radius-5 {
  border-radius: 5px;
}

.radius-10 {
  border-radius: 10px;
}

/* Box Shadow */
.box-shadow {
  box-shadow: 0 5px 40px rgba(0, 0, 0, 0.05);
}

.no-shadow {
  box-shadow: none;
}

/* List Style */
.list-inline {
  margin-bottom: 0;
}

.list-inline li {
  display: inline-block;
}

.list-check {
  margin-bottom: 0;
  padding: 0;
  list-style: none;
}

.list-check li {
  font-size: 18px;
  line-height: 1.67;
  padding-left: 35px;
  position: relative;
}

.list-check li:not(:last-child) {
  margin-bottom: 20px;
}

.list-check li:after {
  position: absolute;
  font-family: 'FontAwesome';
  content: "";
  line-height: inherit;
  left: 5px;
  top: -2px;
}

.list-check li:before {
  position: absolute;
  left: 0;
  top: 5px;
  content: '';
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background-color: #FF988D;
  z-index: -1;
  opacity: .2;
}

.social_icon_list {
  padding: 0;
  margin: 0;
  list-style: none;
  line-height: 1;
}

.social_icon_list li {
  display: inline-block;
}

.social_icon_list li:not(:last-child) {
  margin-right: 25px;
}

.social_icon_list li a {
  font-size: 16px;
}

/* Owl Dots */
.owl-carousel .owl-dots {
  position: absolute;
  right: 50px;
  top: 50%;
  transform: translateY(-50%);
  width: 30px;
}

.owl-carousel button.owl-dot {
  display: inline-block;
  height: 3px;
  width: 16px;
}

.owl-carousel button.owl-dot.active {
  width: 28px;
}

/* Theme Input Group */
.theme-input-group {
  position: relative;
  width: 100%;
}

.theme-input-group input {
  width: 100%;
  height: 50px;
  border: none;
  padding-left: 15px;
  padding-right: 50px;
  color: #ffffff;
  background-color: #334E95;
}

.theme-input-group button {
  position: absolute;
  top: 4px;
  right: 4px;
  height: calc(100% - 8px);
  padding: 9px 28px;
  line-height: 1;
  font-weight: 600;
  color: #ffffff;
}

.theme-input-group button:hover {
  background-color: #fb9389;
}

/* Theme Input Style */
.theme-input-style {
  border: none;
  height: 52px;
  width: 100%;
  padding: 0 20px;
  background-color: #F9F9F9;
  font-size: 16px;
}

.theme-input-style:focus {
  background-color: #fffcf6;
}

textarea.theme-input-style {
  height: 200px;
  padding: 20px 20px;
}

/* Others */
.ov-hidden {
  overflow: hidden;
}

.ovx-hidden {
  overflow-x: hidden;
}

/* Pagination*/
.pagination {
  margin-top: 20px;
}

.pagination li {
  margin: 0 10px;
}

.pagination li a,
.pagination li span {
  width: 50px;
  height: 50px;
  line-height: 1;
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background-color: #f6f6f6;
  color: #5E5E5E;
  font-size: 24px;
}

.pagination li a:hover, .pagination li a.active,
.pagination li span:hover,
.pagination li span.active {
  color: #ffffff;
}

.pagination li.nav-btn a.disabled {
  cursor: not-allowed;
  opacity: .5;
}

/* Blockquote */
blockquote {
  padding: 50px 20px;
  position: relative;
  font-size: 24px;
  line-height: 1.33;
  font-weight: 500;
}

blockquote cite {
  display: block;
  margin-top: 15px;
  font-size: 18px;
  line-height: 1.44;
  font-style: normal;
  font-weight: 400;
}

blockquote:after {
  position: absolute;
  content: '“';
  left: 50%;
  top: 50%;
  font-size: 240px;
  font-weight: 700;
  line-height: 41px;
  transform: translate(-50%, -50%);
  opacity: .1;
}

/* Form Response */
.form-response {
  background-color: rgba(0, 33, 122, 0.2);
}

.form-response span {
  display: block;
  margin-top: 20px;
  margin-bottom: 30px;
  padding: 10px 15px;
}

/* Border Effect */
.border-effect, .btn, .single-service {
  position: relative;
  border: 2px solid #e7e7e7 !important;
  z-index: 1;
}

.border-effect:before, .btn:before, .single-service:before, .border-effect:after, .btn:after, .single-service:after {
  position: absolute;
  content: '';
  display: block;
  height: 30px;
  width: 30px;
  transition: .4s ease-in;
  z-index: -1;
}

.border-effect:before, .btn:before, .single-service:before {
  border-bottom: 2px solid;
  border-left: 2px solid;
  left: -2px;
  bottom: -2px;
}

.border-effect:after, .btn:after, .single-service:after {
  border-top: 2px solid;
  border-right: 2px solid;
  right: -2px;
  top: -2px;
}

.border-effect:hover:after, .btn:hover:after, .single-service:hover:after, .border-effect:hover:before, .btn:hover:before, .single-service:hover:before {
  width: calc(100% + 4px);
  height: calc(100% + 2px);
}

/* Title Border */
.title-border {
  font-size: 0;
  line-height: 1;
}

.title-border span {
  display: inline-block;
  height: 3px;
  width: 12px;
}

.title-border span:first-child {
  width: 24px;
}

.title-border span:not(:last-child) {
  margin-right: 3px;
}

/* Bg */
.light-bg, .single-testimonial .single-testimonial-inner:after {
  background-color: #FFFCF6;
}

/* Search Form */
.search-form .theme-input-group input, .widget.widget_search .theme-input-group input, .widget.widget_newsletter.style--two .theme-input-group input {
  background-color: #F9F9F9;
}

.search-form .theme-input-group input::placeholder, .widget.widget_search .theme-input-group input::placeholder, .widget.widget_newsletter.style--two .theme-input-group input::placeholder {
  color: #C9C9C9 !important;
}

.search-form .theme-input-group input:focus, .widget.widget_search .theme-input-group input:focus, .widget.widget_newsletter.style--two .theme-input-group input:focus {
  background-color: #fffcf6;
}

/* ************************
   02.2: Animations
   ********************* */
.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: backwards;
  animation-fill-mode: backwards;
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
}

@-webkit-keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.fadeOut {
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut;
}

@keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -40px, 0);
    transform: translate3d(0, -40px, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}

.fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 40px, 0);
    transform: translate3d(0, 40px, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}

.fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
}

@keyframes matrix {
  0% {
    transform: matrix(1, 0, 0, 1, 0, 0);
  }
  25% {
    transform: matrix(1, 0, 0, 1, 15, 20);
  }
  50% {
    transform: matrix(1, 0, 0, 1, -5, 25);
  }
  75% {
    transform: matrix(1, 0, 0, 1, -15, 15);
  }
  100% {
    transform: matrix(1, 0, 0, 1, 0, 0);
  }
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
}

@-webkit-keyframes slideOutUp {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
}

@keyframes slideOutUp {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
}

.slideOutUp {
  -webkit-animation-name: slideOutUp;
  animation-name: slideOutUp;
}

@-webkit-keyframes slideInDown {
  from {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    visibility: visible;
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes slideInDown {
  from {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    visibility: visible;
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.slideInDown {
  -webkit-animation-name: slideInDown;
  animation-name: slideInDown;
}

@keyframes inner-circles-loader {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(360deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

/* ************************
   02.3: Preloader
   ********************* */
.preloader {
  z-index: 999999;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
}

.preloader .loader {
  transform: translate3d(0, 0, 0);
  position: relative;
  display: inline-block;
  width: 50px;
  height: 50px;
  background: #FB8579;
  border-radius: 50%;
  overflow: hidden;
  text-indent: -9999px;
  mask-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAA5JREFUeNpiYGBgAAgwAAAEAAGbA+oJAAAAAElFTkSuQmCC);
}

.preloader .loader:before, .preloader .loader:after {
  content: '';
  position: absolute;
  top: 0;
  display: inline-block;
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.preloader .loader:before {
  animation: inner-circles-loader 3s infinite;
  transform-origin: 0 50%;
  left: 0;
  background: #FCADA5;
}

.preloader .loader:after {
  animation: inner-circles-loader 3s 0.2s reverse infinite;
  transform-origin: 100% 50%;
  right: 0;
  background: #FDC8C3;
}

/* ************************
   02.4: Buttons
   ********************* */
.btn {
  display: inline-block;
  padding: 14px 30px;
  border-radius: 0;
  font-weight: 600;
  line-height: 1;
  font-size: 18px;
  position: relative;
  cursor: pointer;
}

.btn:before, .btn:after {
  height: 10px;
  width: 10px;
}

.btn:focus, .btn.focus {
  box-shadow: none;
}

/* Button Inline */
.btn-inline {
  display: inline-block;
  position: relative;
  font-size: 18px;
  font-weight: 600;
}

.btn-inline:after {
  position: absolute;
  content: '';
  left: 0;
  bottom: 0;
  width: 0;
  height: 1.5px;
}

.btn-inline:hover:after {
  width: 100%;
}

/* ************************
   02.5: Section Title
   ********************* */
.section-title {
  margin-bottom: 70px;
}

@media only screen and (max-width: 991px) {
  .section-title {
    margin-bottom: 50px;
  }
}

.section-title h2 {
  font-size: 40px;
  line-height: 1.35;
  margin-top: -10px;
}

@media only screen and (max-width: 575px) {
  .section-title h2 {
    font-size: 36px;
  }
}

.section-title.style--two {
  margin-bottom: 0;
}

.section-title.style--two .title-border {
  margin-bottom: 30px;
}

.section-title.style--two h2 {
  font-size: 34px;
  line-height: 1.23;
  margin-bottom: 30px;
}

@media only screen and (max-width: 575px) {
  .section-title.style--two h2 {
    font-size: 30px;
  }
}

.section-title.style--two p {
  font-size: 18px;
  line-height: 1.67;
  margin-bottom: 20px;
}

.section-title.style--two p b {
  font-weight: 600;
  color: #131313;
}

@media only screen and (max-width: 991px) {
  .section-title br {
    display: none;
  }
}

/* ************************
   02.6: Page Title
   ********************* */
.page-title h2 {
  margin-top: -13px;
  margin-bottom: 8px;
  font-size: 48px;
  line-height: 1.375;
}

.page-title ul {
  margin-bottom: -8px;
}

.page-title ul li {
  font-size: 24px;
  font-weight: 700;
}

.page-title ul li:not(:last-child) {
  position: relative;
  padding-right: 15px;
  margin-right: 10px;
}

.page-title ul li:not(:last-child):after {
  right: 0;
  position: absolute;
  content: '/';
  top: 50%;
  transform: translateY(-50%);
}

/* ************************
   02.7: Widget
   ********************* */
.widget {
  background-color: #FBFBFB;
  padding: 40px 30px;
  font-size: 16px;
}

.widget:not(:last-child) {
  margin-bottom: 50px;
}

.widget .widget-title {
  margin-bottom: 30px;
}

.widget .widget-title h4 {
  font-weight: 500;
}

.widget ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

.widget.widget_newsletter .newsletter-content p {
  margin-bottom: 25px;
}

.widget.widget_newsletter.style--two .newsletter-content p {
  font-size: 14px;
}

.widget.widget_newsletter.style--two .newsletter-content .theme-input-group button {
  position: static;
  width: 100%;
  margin-top: 16px;
  height: 52px;
}

.widget.widget_newsletter.style--two .newsletter-content .theme-input-group input {
  background-color: #ffffff;
}

.widget.widget_contact .contact-content p {
  margin-bottom: 20px;
}

.widget.widget_contact .contact-content ul li {
  padding-left: 30px;
  position: relative;
  font-size: 14px;
  line-height: 2;
}

.widget.widget_contact .contact-content ul li:not(:last-child) {
  margin-bottom: 5px;
}

.widget.widget_contact .contact-content ul li i {
  position: absolute;
  left: 0;
  line-height: inherit;
}

.widget.widget_service .service-list li {
  line-height: 2;
  display: flex;
  align-items: center;
}

.widget.widget_service .service-list li a:hover i,
.widget.widget_service .service-list li a:hover svg * {
  fill: #FB8579;
}

.widget.widget_service .service-list li i {
  font-size: 18px;
}

.widget.widget_service .service-list li img,
.widget.widget_service .service-list li svg,
.widget.widget_service .service-list li i {
  margin-right: 10px;
}

.widget.widget_service .service-list li:not(:last-child) {
  margin-bottom: 24px;
}

.widget.widget_recent_entries .single-post:not(:last-child) {
  margin-bottom: 25px;
}

.widget.widget_recent_entries .single-post .post-image {
  margin-right: 20px;
}

.widget.widget_recent_entries .single-post .post-content .posted-on {
  display: block;
  margin-bottom: 5px;
}

.widget.widget_recent_entries .single-post .post-content h5 {
  line-height: 1.375;
  font-size: 16px;
  font-weight: 500;
}

.widget.widget_tag_cloud .tagcloud {
  margin: 0 -6px -6px 0;
}

.widget.widget_tag_cloud .tagcloud a {
  display: inline-block;
  padding: 5px 7px;
  background-color: #ffffff;
  margin: 0 6px 6px 0;
}

/* ************************
   02.8: Back to Top
   ********************* */
.back-to-top {
  position: fixed;
  opacity: 0;
  visibility: hidden;
  cursor: pointer;
  width: 35px;
  height: 35px;
  box-shadow: 0 4px 14px rgba(0, 0, 0, 0.1);
  font-size: 16px;
  color: #ffffff !important;
  border-radius: 50%;
  display: inline-block;
  text-align: center;
  line-height: 35px;
  bottom: 10%;
  right: 4%;
  transform: scale(0);
  transition: .3s ease-in;
}

.back-to-top.show {
  transform: scale(1);
  opacity: 1;
  visibility: visible;
  z-index: 999;
}

.back-to-top:hover {
  opacity: .6;
}

/* ************************
   03.1: Header
   ********************* */
.header {
  position: relative;
  min-height: 100px;
}

.header .header-main {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  padding: 30px 140px;
}

@media only screen and (max-width: 1199px) {
  .header .header-main {
    padding: 30px 80px;
  }
}

@media only screen and (max-width: 991px) {
  .header .header-main {
    padding: 0;
  }
}

.header .header-main.sticky {
  padding-top: 0;
  padding-bottom: 0;
  position: fixed;
  width: 100%;
  z-index: 999;
  left: 0;
  top: 0;
}

.header .header-main.sticky .row {
  background-color: #ffffff;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
  margin-top: 20px;
}

@media only screen and (max-width: 479px) {
  .header .header-main.sticky .row {
    margin-top: 0;
  }
}

.header .header-main .main-menu-wrapper {
  min-height: 100px;
  user-select: none;
  position: relative;
  z-index: 3;
  padding-left: 25px;
  padding-right: 25px;
}

@media only screen and (max-width: 991px) {
  .header .header-main .main-menu-wrapper {
    margin-left: 25px;
    margin-right: 25px;
  }
}

@media only screen and (max-width: 767px) {
  .header .header-main .main-menu-wrapper {
    padding-left: 0;
    padding-right: 0;
  }
}

@media only screen and (max-width: 479px) {
  .header .header-main .main-menu-wrapper {
    margin-left: -15px;
    margin-right: -15px;
  }
}

.header .header-main .main-menu-wrapper:after {
  content: '';
  position: absolute;
  display: block;
  width: 0;
  height: 100%;
  top: 0;
  left: 0;
  background: #ffffff;
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.06);
  z-index: -1;
}

.header .header-main .main-menu-wrapper.show:after {
  width: 100%;
}

.header .header-main .menu-trigger,
.header .header-main #menu-button {
  display: inline-flex;
  height: 25px;
  width: 30px;
  align-items: center;
  cursor: pointer;
  margin-right: 20px;
}

.header .header-main .menu-trigger span,
.header .header-main #menu-button span {
  position: relative;
  width: 22px;
}

.header .header-main .menu-trigger span, .header .header-main .menu-trigger span:before, .header .header-main .menu-trigger span:after,
.header .header-main #menu-button span,
.header .header-main #menu-button span:before,
.header .header-main #menu-button span:after {
  display: block;
  height: 2px;
  border-radius: 2px;
}

.header .header-main .menu-trigger span:before, .header .header-main .menu-trigger span:after,
.header .header-main #menu-button span:before,
.header .header-main #menu-button span:after {
  position: absolute;
  content: '';
}

.header .header-main .menu-trigger span::before,
.header .header-main #menu-button span::before {
  top: -7px;
  width: 27px;
}

.header .header-main .menu-trigger span::after,
.header .header-main #menu-button span::after {
  top: 7px;
  width: 16px;
}

.header .header-main .menu-trigger:hover span,
.header .header-main .menu-trigger:hover span:before,
.header .header-main .menu-trigger:hover span:after,
.header .header-main #menu-button:hover span,
.header .header-main #menu-button:hover span:before,
.header .header-main #menu-button:hover span:after {
  width: 27px;
}

.header .header-main .menu-trigger.active span, .header .header-main .menu-trigger.menu-opened span,
.header .header-main #menu-button.active span,
.header .header-main #menu-button.menu-opened span {
  background-color: transparent;
}

.header .header-main .menu-trigger.active span:before, .header .header-main .menu-trigger.menu-opened span:before,
.header .header-main #menu-button.active span:before,
.header .header-main #menu-button.menu-opened span:before {
  width: 25px;
  transform: translateY(7px) rotate(45deg);
}

.header .header-main .menu-trigger.active span:after, .header .header-main .menu-trigger.menu-opened span:after,
.header .header-main #menu-button.active span:after,
.header .header-main #menu-button.menu-opened span:after {
  width: 25px;
  transform: translateY(-7px) rotate(-45deg);
}

@media only screen and (max-width: 991px) {
  .header .header-main .menu-trigger {
    display: none;
  }
}

.header .header-main .main-menu #menu-button {
  display: none;
  font-size: 28px;
  cursor: pointer;
}

@media only screen and (max-width: 991px) {
  .header .header-main .main-menu #menu-button {
    display: inline-flex;
  }
}

.header .header-main .main-menu #menu-button span, .header .header-main .main-menu #menu-button span:before, .header .header-main .main-menu #menu-button span:after {
  width: 25px;
}

.header .header-main .main-menu .nav-wrapper {
  position: relative;
  display: none !important;
  transition: 0.7s cubic-bezier(0.07, 0.98, 0, 0.96);
  left: -200px;
}

@media only screen and (max-width: 991px) {
  .header .header-main .main-menu .nav-wrapper {
    position: static;
    left: 0;
    display: block !important;
  }
}

.header .header-main .main-menu .nav-wrapper.active {
  display: block !important;
  left: 0;
  width: max-content;
}

.header .header-main .main-menu .nav li {
  position: relative;
}

.header .header-main .main-menu .nav li a {
  display: block;
  line-height: 1;
  position: relative;
  z-index: 1;
  text-transform: capitalize;
}

.header .header-main .main-menu .nav li ul {
  position: absolute;
  left: 0;
  top: -2000px;
  opacity: 0;
  visibility: hidden;
  background-color: #ffffff;
  width: 210px;
  padding: 15px 0;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.05);
  transform: translate3d(0, -5px, 0);
  transition: transform 0.2s ease-out;
  z-index: 9;
}

.header .header-main .main-menu .nav li ul li {
  display: block;
  width: 100%;
  line-height: 1;
  font-size: 16px;
}

.header .header-main .main-menu .nav li ul li:hover > a {
  margin-left: 5px;
}

.header .header-main .main-menu .nav li ul li a {
  padding: 10px 30px;
}

.header .header-main .main-menu .nav li ul li ul {
  top: 0;
  left: calc(100% + 20px);
}

.header .header-main .main-menu .nav li ul li:hover > ul {
  opacity: 1;
  visibility: visible;
  top: 0;
  left: 100%;
}

.header .header-main .main-menu .nav > li {
  display: inline-block;
  padding: 0 15px;
}

.header .header-main .main-menu .nav > li > a {
  line-height: 100px;
  position: relative;
  font-weight: 600;
}

.header .header-main .main-menu .nav > li:hover > ul {
  opacity: 1;
  visibility: visible;
  top: 100%;
  transform: translate3d(0, 0, 0);
}

.header .header-main .main-menu .offcanvas-trigger {
  display: inline-block;
  width: 30px;
  cursor: pointer;
}

.header .header-main .main-menu .offcanvas-trigger span {
  display: block;
  height: 3px;
}

.header .header-main .main-menu .offcanvas-trigger span:nth-child(2) {
  margin: 5px 0;
  margin-left: 5px;
}

.header .header-main .main-menu .offcanvas-trigger span:nth-child(3) {
  margin-left: 10px;
}

.header .header-main .main-menu .offcanvas-trigger:hover span {
  margin-left: 0 !important;
}

/* Mobile Menu */
@media only screen and (max-width: 991px) {
  .header .header-main .row {
    position: relative;
  }
  .header .header-main .row div:nth-child(1) {
    position: static;
  }
  .header .header-main .row .main-menu {
    text-align: right;
    position: initial;
  }
  .header .header-main .row .main-menu #menu-button {
    display: inline-flex;
    height: 25px;
    width: 25px;
    align-items: center;
    justify-content: center;
  }
  .header .header-main .row .main-menu ul.nav {
    position: absolute;
    top: 100%;
    left: 50%;
    right: 50%;
    width: 100%;
    background: #fff;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.05);
    max-height: 350px;
    overflow-y: auto;
    padding: 15px 20px;
    max-width: 800px;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    z-index: 99999;
    margin-right: 0;
  }
  .header .header-main .row .main-menu ul.nav > li {
    padding: 0;
  }
  .header .header-main .row .main-menu ul.nav ul {
    position: relative;
    left: 0;
    right: auto;
    top: 0;
    width: 100%;
    display: none;
    padding: 0;
    opacity: 1;
    visibility: visible;
    text-align: left;
    z-index: 99999;
    box-shadow: none;
    transform: translate3d(0, 0, 0);
  }
  .header .header-main .row .main-menu ul.nav ul li {
    overflow: hidden;
  }
  .header .header-main .row .main-menu ul.nav ul li:hover > a {
    margin-left: 0;
  }
  .header .header-main .row .main-menu ul.nav ul ul {
    position: relative;
    margin-left: 30px;
    left: 0 !important;
    opacity: 1;
    visibility: visible;
  }
  .header .header-main .row .main-menu ul {
    text-align: left;
  }
  .header .header-main .row .main-menu ul li {
    display: block;
    width: 100%;
  }
  .header .header-main .row .main-menu ul li a {
    line-height: 1 !important;
    padding: 12px 15px;
    color: #00217A;
  }
  .header .header-main .row .main-menu ul li.current-menu-parent > a,
  .header .header-main .row .main-menu ul li.current-menu-item > a {
    color: #FB8579 !important;
  }
  .header .header-main .row .main-menu .submenu-button {
    display: block;
    position: absolute;
    z-index: 9;
    top: 0;
    right: 15px;
    width: 40px;
    height: inherit;
    line-height: 40px;
    text-align: center;
    color: initial;
  }
  .header .header-main .row .main-menu .submenu-button:before {
    font-family: 'FontAwesome';
    content: "";
  }
  .header .header-main .row .main-menu .submenu-button.submenu-opened:before {
    content: '\f106';
    color: #FB8579;
  }
}

/* ************************
   03.2: Banner
   ********************* */
.banner {
  padding-top: 140px;
  position: relative;
  overflow: hidden;
  background-size: contain;
  background-position: bottom center;
  background-repeat: no-repeat;
}

@media only screen and (max-width: 991px) {
  .banner {
    padding-top: 100px;
  }
}

.banner .banner_content {
  margin-bottom: 100px;
}

.banner .banner_content h1 {
  font-size: 60px;
  line-height: 1.37;
  animation-delay: 0s;
  margin-top: -16px;
}

@media only screen and (max-width: 575px) {
  .banner .banner_content h1 {
    font-size: 40px;
    margin-top: -10px;
  }
}

.banner .banner_content p {
  line-height: 1.67;
  max-width: 780px;
  margin: 20px auto 40px;
  animation-delay: .5s;
}

.banner .banner_content .btn {
  animation-delay: .7s;
}

.banner .banner-images img {
  width: auto !important;
  margin: 0 auto;
}

.banner .banner-images .search-icon-box {
  right: 30px;
  top: -20px;
  width: 150px;
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 3;
}

@media only screen and (max-width: 991px) {
  .banner .banner-images .search-icon-box {
    right: -40px;
    top: -40px;
  }
}

.banner .banner-images .search-icon-box img {
  animation: matrix 5s linear infinite;
}

.banner .banner-images .banner-icon {
  position: absolute;
  z-index: -1;
  animation-duration: 5s;
}

.banner .banner-images .banner-icon.icon-1 {
  top: -20px;
  right: 65px;
}

@media only screen and (max-width: 991px) {
  .banner .banner-images .banner-icon.icon-1 {
    top: -30px;
    right: 25px;
  }
}

@media only screen and (max-width: 575px) {
  .banner .banner-images .banner-icon.icon-1 {
    top: -30px;
    right: 65px;
  }
}

@media only screen and (max-width: 479px) {
  .banner .banner-images .banner-icon.icon-1 {
    right: 35px;
  }
}

@media only screen and (max-width: 379px) {
  .banner .banner-images .banner-icon.icon-1 {
    right: 20px;
  }
}

.banner .banner-images .banner-icon.icon-2 {
  left: 120px;
  top: 50px;
  z-index: 1;
}

@media only screen and (max-width: 991px) {
  .banner .banner-images .banner-icon.icon-2 {
    left: 60px;
    top: 40px;
  }
}

@media only screen and (max-width: 575px) {
  .banner .banner-images .banner-icon.icon-2 {
    left: 125px;
  }
}

@media only screen and (max-width: 479px) {
  .banner .banner-images .banner-icon.icon-2 {
    left: 90px;
  }
}

@media only screen and (max-width: 379px) {
  .banner .banner-images .banner-icon.icon-2 {
    left: 70px;
  }
}

.banner .banner-images .banner-icon.icon-3 {
  left: 90px;
  top: 20px;
}

@media only screen and (max-width: 991px) {
  .banner .banner-images .banner-icon.icon-3 {
    left: 40px;
  }
}

@media only screen and (max-width: 575px) {
  .banner .banner-images .banner-icon.icon-3 {
    left: 80px;
  }
}

@media only screen and (max-width: 479px) {
  .banner .banner-images .banner-icon.icon-3 {
    left: 50px;
  }
}

@media only screen and (max-width: 379px) {
  .banner .banner-images .banner-icon.icon-3 {
    left: 40px;
  }
}

.banner .banner_slider .owl-dots .owl-dot {
  display: block;
  margin: 7px auto;
}

.banner .slider-counter {
  position: absolute;
  left: 50px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 24px;
  font-weight: 700;
}

.banner .slider-counter span {
  font-size: 20px;
}

/* ************************
   03.3: Blog
   ********************* */
/* Single Blog */
.single-blog-item {
  position: relative;
  margin-bottom: 50px;
}

.single-blog-item .blog-image img {
  display: block;
  width: 100%;
}

.single-blog-item .blog-content {
  margin-top: 30px;
}

.single-blog-item .blog-content h3 {
  line-height: 1.25;
  margin-bottom: 20px;
  margin-top: 10px;
}

/* Blog Details */
.blog-details .blog-details-image {
  margin-bottom: 40px;
}

.blog-details .post-meta {
  margin-bottom: 20px;
}

.blog-details .post-meta li {
  font-weight: 600;
  font-size: 16px;
}

.blog-details .post-meta li:not(:last-child) {
  margin-right: 38px;
}

.blog-details .post-meta li a {
  font-weight: 400;
}

.blog-details .post-meta li a:not(:last-child):after {
  content: ',';
}

.blog-details .post-title {
  margin-bottom: 40px;
}

.blog-details .post-title h2 {
  font-size: 40px;
  line-height: 1.23;
}

.blog-details .blog-details-content {
  margin-bottom: 60px;
}

.blog-details .blog-details-content p {
  margin-bottom: 50px;
}

.blog-details .blog-details-content blockquote {
  margin-top: 30px;
}

.blog-details .blog-details-content blockquote p {
  margin-bottom: 0;
}

.blog-details .blog-details-content blockquote,
.blog-details .blog-details-content h3,
.blog-details .blog-details-content img {
  margin-bottom: 30px;
}

.blog-details .blog-details-content p:last-child {
  margin-bottom: 0;
}

.blog-details .post-tags li a {
  display: inline-block;
  font-size: 12px;
  padding: 5px 7px;
  background-color: #F7F7F7;
}

.blog-details .post-tags li a:not(:last-child) {
  margin-right: 5px;
}

.blog-details .single-post-pagination {
  max-width: 300px;
}

.blog-details .single-post-pagination .posted-on {
  line-height: 1;
  font-size: 12px;
}

.blog-details .single-post-pagination h5 {
  line-height: 1.375;
  font-weight: 500;
}

.blog-details .single-post-pagination .pagination-image {
  margin-right: 20px;
  position: relative;
  z-index: 2;
  overflow: hidden;
}

.blog-details .single-post-pagination .pagination-image i {
  position: absolute;
  left: 5px;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 25px;
  color: #ffffff;
  opacity: 0;
  visibility: hidden;
  z-index: 3;
}

.blog-details .single-post-pagination .pagination-image:after {
  position: absolute;
  content: '';
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  visibility: hidden;
}

.blog-details .single-post-pagination a:hover .pagination-image:after {
  opacity: 1;
  visibility: visible;
}

.blog-details .single-post-pagination a:hover .pagination-image i {
  left: 0;
  opacity: 1;
  visibility: visible;
}

.blog-details .single-post-pagination.next .pagination-image {
  margin-right: 0;
  margin-left: 20px;
}

.blog-details .single-post-pagination.next .pagination-image i {
  left: -5px;
}

.blog-details .post-comments-wrap h4 {
  font-size: 20px;
}

.blog-details .post-comments-wrap .single-comment-wrapper:not(:last-child) {
  padding-bottom: 30px;
  border-bottom: 1px solid #f7f7f7;
}

.blog-details .post-comments-wrap .single-comment-wrapper .single-post-comment {
  margin-top: 30px;
}

.blog-details .post-comments-wrap .single-comment-wrapper .single-post-comment .comment-author-image {
  margin-right: 20px;
}

.blog-details .post-comments-wrap .single-comment-wrapper .single-post-comment .comment-content {
  line-height: 1.5;
}

.blog-details .post-comments-wrap .single-comment-wrapper .single-post-comment .comment-content h5 {
  margin-right: 30px;
}

.blog-details .post-comments-wrap .single-comment-wrapper .single-post-comment .comment-content span {
  font-size: 12px;
}

.blog-details .post-comments-wrap .single-comment-wrapper .single-post-comment .comment-content p {
  margin: 5px 0;
  font-size: 14px;
  line-height: 1.7;
}

.blog-details .post-comments-wrap .single-comment-wrapper .single-post-comment .comment-content .reply-btn {
  line-height: 1;
  font-size: 16px;
  text-transform: capitalize;
}

.blog-details .post-comments-wrap .single-comment-wrapper .post-comment-reply {
  margin-left: 120px;
  margin-top: 60px;
}

@media only screen and (max-width: 575px) {
  .blog-details .post-comments-wrap .single-comment-wrapper .post-comment-reply {
    margin-left: 30px;
  }
}

.blog-details .post-comments-wrap .single-comment-wrapper .post-comment-reply .post-comment-reply .post-comment-reply {
  margin-left: 0;
}

.blog-details .comments-form h4 {
  font-size: 20px;
  margin-bottom: 30px;
  text-transform: capitalize;
}

.blog-details .comments-form .theme-input-style {
  margin-bottom: 30px;
}

/* ************************
   03.4: Footer
   ********************* */
.footer {
  padding-top: 80px;
  padding-bottom: 30px;
}

.footer p {
  line-height: 1.75;
}

.footer p,
.footer a,
.footer li {
  color: #CFCFCF;
}

.footer .theme-input-group input::placeholder {
  color: #FFFAF9 !important;
}

.footer .title-border span:first-child {
  width: 12px;
}

.footer .title-border span:nth-child(2) {
  width: 20px;
  background-color: #ffffff;
}

.footer .widget {
  margin-bottom: 30px;
  padding: 0;
  background-color: transparent;
}

.footer .widget:last-child {
  margin-bottom: 50px;
}

.footer .widget .widget-title h3 {
  line-height: 1.25;
  color: #ffffff;
  margin-bottom: 10px;
}

.footer .widget .widget-logo,
.footer .widget .widget-title {
  margin-bottom: 40px;
}

/* ************************
   03.5: 404
   ********************* */
.content404 h1, .content-coming-soon h1 {
  font-size: 80px;
  line-height: 1.36;
  margin-bottom: 20px;
}

@media only screen and (max-width: 575px) {
  .content404 h1, .content-coming-soon h1 {
    font-size: 60px;
  }
}

.content404 > p, .content-coming-soon > p {
  margin-bottom: 40px;
}

.content404 .search-form p, .content-coming-soon .search-form p, .content404 .widget.widget_search p, .content-coming-soon .widget.widget_search p, .content404 .widget.widget_newsletter.style--two p, .content-coming-soon .widget.widget_newsletter.style--two p {
  font-weight: 600;
  margin-bottom: 20px;
}

/* ************************
   03.6: Coming Soon
   ********************* */
.content-coming-soon h1 {
  font-size: 60px;
  line-height: 1.1;
}

@media only screen and (max-width: 575px) {
  .content-coming-soon h1 {
    font-size: 40px;
  }
}

/* ************************
   04.1: Service
   ********************* */
.single-service {
  padding: 60px 40px;
  background-color: #ffffff;
  margin-bottom: 30px;
}

.single-service .icon {
  margin-bottom: 30px;
}

.single-service .content h3 {
  margin-bottom: 20px;
}

.single-service .content p {
  line-height: 1.67;
}

/* ************************
   04.2: Pricing
   ********************* */
.single-pricing-table {
  padding: 60px 40px;
  background-color: #ffffff;
  margin-bottom: 30px;
}

.single-pricing-table .pt-header .icon {
  margin-bottom: 10px;
}

.single-pricing-table .pt-header .title {
  line-height: 1.23;
  margin-bottom: 30px;
}

.single-pricing-table .pt-body {
  font-size: 18px;
  line-height: 1.67;
  border-bottom: 1px solid #CFCFCF;
}

.single-pricing-table .pt-body ul {
  margin-bottom: 30px;
}

.single-pricing-table .pt-body ul li:not(:last-child) {
  margin-bottom: 24px;
}

.single-pricing-table .pt-footer .price {
  font-size: 60px;
  line-height: 1.37;
  margin: 10px 0 30px;
}

.single-pricing-table.active .btn {
  background-color: #ffffff;
  border: none !important;
}

.single-pricing-table.active .btn:after, .single-pricing-table.active .btn:before {
  display: none;
}

/* ************************
   04.3: Testimonial
   ********************* */
.single-testimonial {
  padding: 60px;
  margin: -60px 0;
}

@media only screen and (max-width: 575px) {
  .single-testimonial {
    padding: 30px;
    margin: -30px 0;
  }
}

@media only screen and (max-width: 379px) {
  .single-testimonial {
    padding: 10px;
    margin: -10px 0;
  }
}

.single-testimonial .single-testimonial-inner {
  position: relative;
  margin-top: 20px;
  margin-bottom: 50px;
  padding: 65px 80px 55px;
  background-color: #ffffff;
  box-shadow: 0 10px 26px rgba(0, 0, 0, 0.1);
}

@media only screen and (max-width: 575px) {
  .single-testimonial .single-testimonial-inner {
    padding: 40px 30px;
  }
}

.single-testimonial .single-testimonial-inner:after {
  position: absolute;
  content: '';
  left: 0;
  top: 40px;
  width: calc(100% + 120px);
  height: calc(100% - 80px);
  z-index: -1;
}

.single-testimonial .single-testimonial-inner .content {
  max-width: 550px;
  margin: 0 auto;
}

.single-testimonial .single-testimonial-inner .content p {
  margin-bottom: 20px;
  line-height: 1.67;
}

.single-testimonial .single-testimonial-inner .content .author {
  margin-bottom: 20px;
}

.single-testimonial .single-testimonial-inner .image img {
  border-radius: 50%;
  width: auto;
  margin: 0 auto;
}

/* Testimonial Slider */
.testimonial-slider {
  margin-top: -20px;
}

.testimonial-slider .owl-dots {
  position: static;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.testimonial-slider .owl-dots .owl-dot {
  margin: 0 3px;
}

/* brand-subtitle */
.brand-subtitle h3 {
  font-size: 28px;
  font-weight: 400;
  color: #CFCFCF;
  margin-bottom: 80px;
}

/* Partners */
.brand-logo .single-brand-logo {
  display: block;
  height: 80px;
}

.brand-logo .single-brand-logo img {
  width: auto !important;
  margin: 0 auto;
}

.brand-logo .single-brand-logo img:first-child {
  opacity: .4;
}

.brand-logo .single-brand-logo img:only-child {
  opacity: 1;
}

.brand-logo .single-brand-logo:hover img:first-child {
  transform: translate3d(0, -150%, -100px);
}

.brand-logo .single-brand-logo:hover img:last-child {
  transform: translate3d(0, -100%, -100px);
}

.brand-logo .single-brand-logo:hover img:only-child {
  transform: translate3d(0, 0, 0);
}

/* ************************
   04.4: Contact
   ********************* */
.single-contact-info {
  margin-bottom: 30px;
  padding: 40px 28px;
  box-shadow: 0 10px 26px rgba(0, 0, 0, 0.1);
}

.single-contact-info h3 {
  line-height: 1.25;
  margin-bottom: 15px;
}

.single-contact-info p a {
  display: block;
  line-height: 1.67;
}

.contact-form-wrapper h2 {
  font-size: 40px;
  line-height: 1.35;
  margin-bottom: 30px;
}

.contact-form-wrapper > p {
  font-size: 18px;
  margin-bottom: 40px;
}

.contact-form .theme-input-style {
  margin-bottom: 30px;
}
