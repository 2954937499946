/* ************************
   02.6: Page Title
   ********************* */
.page-title {
    h2 {
        margin-top: -13px;
        margin-bottom: 8px;
        font-size: 48px;
        line-height: 1.375;
    }
    ul {
        margin-bottom: -8px;
        li {
            font-size: 24px;
            font-weight: $bold;
            @extend .c2;
            @extend .alegreya;
            &:not(:last-child) {
                position: relative;
                padding-right: 15px;
                margin-right: 10px;
                @extend .c1;
                &:after {
                    right: 0;
                    position: absolute;
                    content: '/';
                    top: 50%;
                    transform: translateY(-50%);
                }
            }
        }
    }
}