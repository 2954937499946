/* ************************
   01.2: Padding/Margin
   ********************* */
// Padding Top
@for $i from 0 through 15 {
    .pt-#{$i * 10} {
        padding-top : #{$i * 10}px;
    }
}
  
// Padding Bottom    
@for $i from 0 through 15  {
    .pb-#{$i * 10} {
        padding-bottom : #{$i * 10}px;
    }
}

// Margin Top
@for $i from 0 through 15 {
    .mt-#{$i * 10} {
        margin-top : #{$i * 10}px;
    }
}
  
// margin Bottom    
@for $i from 0 through 15  {
    .mb-#{$i * 10} {
        margin-bottom : #{$i * 10}px;
    }
}

.pt-250 {
    padding-top: 250px;
}

@include tab {
    .pt-120 {
        padding-top: 80px;
    }
    .pb-120 {
        padding-bottom: 80px;
    }
    .pb-90 {
        padding-bottom: 50px;
    }
    .pb-70 {
        padding-bottom: 30px;
    }
}

@include tab {
    .pt-140 {
        padding-top: 100px;
    }
    .pb-140 {
        padding-bottom: 100px;
    }
    .pb-110 {
        padding-bottom: 70px;
    }
    .pb-90 {
        padding-bottom: 40px;
    }
}