/* ************************
   02.3: Preloader
   ********************* */
.preloader {
    z-index: 999999;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $white;
    .loader {
        transform: translate3d(0, 0, 0);
        position: relative;
        display: inline-block;
        width: 50px;
        height: 50px;
        background: #FB8579;
        border-radius: 50%;
        overflow: hidden;
        text-indent: -9999px;
        mask-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAA5JREFUeNpiYGBgAAgwAAAEAAGbA+oJAAAAAElFTkSuQmCC);
        &:before,
        &:after {
            content: '';
            position: absolute;
            top: 0;
            display: inline-block;
            width: 50px;
            height: 50px;
            border-radius: 50%;
        }
        &:before {
            animation: inner-circles-loader 3s infinite;
            transform-origin: 0 50%;
            left: 0;
            background: #FCADA5;
        }
        &:after {
            animation: inner-circles-loader 3s 0.2s reverse infinite;
            transform-origin: 100% 50%;
            right: 0;
            background: #FDC8C3;
        }
    }
}