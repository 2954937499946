/* ************************
   04.4: Contact
   ********************* */

.single-contact-info {
    margin-bottom: 30px;
    padding: 40px 28px;
    box-shadow: 0 10px 26px rgba($color: #000000, $alpha: 0.1);
    h3 {
        line-height: 1.25;
        margin-bottom: 15px;
    }
    p {
        a {
            display: block;
            line-height: 1.67;
        }
        span {
            @extend .c2;
        }
    }
}

.contact-form-wrapper {
    h2 {
        font-size: 40px;
        line-height: 1.35;
        margin-bottom: 30px;
    }
    > p {
        font-size: 18px;
        margin-bottom: 40px;
    }
}

.contact-form {
    .theme-input-style {
        margin-bottom: 30px;
    }
}
