/* ************************
   04.2: Pricing
   ********************* */
.single-pricing-table {
    padding: 60px 40px;
    background-color: $white;
    margin-bottom: 30px;
    .pt-header {
        .icon  {
            margin-bottom: 10px;
        }
        .title {
            line-height: 1.23;
            margin-bottom: 30px;
        }
    }
    .pt-body {
        font-size: 18px;
        line-height: 1.67;
        border-bottom: 1px solid #CFCFCF;
        ul {    
            margin-bottom: 30px;
           li {
                &:not(:last-child) {
                    margin-bottom: 24px;
                }
            } 
        }
    }
    .pt-footer {
        .price {
            font-size: 60px;
            line-height:1.37;
            margin: 10px 0 30px;
        }
    }
    &.active {
        @extend .c2-bg;
        @extend .text-white;
        .btn {
            background-color: $white;
            border: none !important;
            @extend .c2;
            &:after,
            &:before {
                display: none;
            }
        }
    }
}