/* ************************
   04.3: Testimonial
   ********************* */
.single-testimonial {
    padding: 60px;
    margin: -60px 0;
    @include mobileMd {
        padding: 30px;
        margin: -30px 0;
    }
    @include mobileXs {
        padding: 10px;
        margin: -10px 0;
    }
    .single-testimonial-inner {
        position: relative;
        margin-top: 20px;
        margin-bottom: 50px;
        padding: 65px 80px 55px;
        background-color: $white;
        box-shadow: 0 10px 26px rgba(0, 0, 0, 0.1);
        @include mobileMd {
            padding: 40px 30px;
        }
        &:after {
            position: absolute;
            content: '';
            left: 0;
            top: 40px;
            width: calc(100% + 120px);
            height: calc(100% - 80px);
            @extend .light-bg;
            z-index: -1;
        }
        .content {
            max-width: 550px;
            margin: 0 auto;
            p {
                margin-bottom: 20px;
                line-height: 1.67;
            }
            .author {
                margin-bottom: 20px;
            }
        }
        .image {
            img {
                border-radius: 50%;
                width: auto;
                margin: 0 auto;
            }
        }
    }
}

/* Testimonial Slider */
.testimonial-slider  {
    margin-top: -20px;
    .owl-dots {
        position: static;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        .owl-dot {
            margin: 0 3px;
        }
    }
}

/* brand-subtitle */
.brand-subtitle {
    h3 {
        font-size: 28px;
        font-weight: $regular;
        color: #CFCFCF;
        margin-bottom: 80px;
    }
}

/* Partners */
.brand-logo {
    .single-brand-logo {
        display: block;
        height: 80px;
        img {
            width: auto !important;
            margin: 0 auto;
            @extend .trans3;
            &:first-child {
                opacity: .4;
            }
            &:only-child {
                opacity: 1;
            }
        } 
        &:hover {
            img {
                &:first-child {
                    transform: translate3d(0, -150%, -100px);
                }
                &:last-child {
                    transform: translate3d(0, -100%, -100px);
                }
                &:only-child {
                    transform: translate3d(0, 0, 0);
                }
            } 
        }
    }
}