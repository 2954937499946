/* ************************
   02.5: Section Title
   ********************* */
.section-title {
    margin-bottom: 70px;
    @include tab {
        margin-bottom: 50px;
    }
    h2 {
        font-size: 40px;
        line-height: 1.35;
        margin-top: -10px;
        @include mobileMd {
            font-size: 36px;
        }
    }
    &.style--two {
        margin-bottom: 0;
        .title-border {
            margin-bottom: 30px;
        }
        h2 {
            font-size: 34px;
            line-height: 1.23;
            margin-bottom: 30px;
            @include mobileMd {
                font-size: 30px;
            }
        }
        p {
            font-size: 18px;
            line-height: 1.67;
            margin-bottom: 20px;
            b {
                font-weight: $semi-bold;
                color: #131313;
            }
        }
    }
    @include tab {
        br {
            display: none;
        }
    }
}