/* ************************
   01.1: Reset
   ********************* */
* {
    outline: none !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
    
input[type="number"] {
    -moz-appearance: textfield;
}
    
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
}
    
::selection {
    text-shadow: none;
    color: #ffffff;
    @extend.c1-bg
}

::placeholder {
    color: $text-color !important;
    opacity: 1 !important;
}
    
iframe {
    max-width: 100%;
}

button:focus,
input:focus,
select:focus,
textarea:focus {
    outline: none !important;
}
    
button,
button[type="submit"],
input[type="submit"] {
    border: none;
    background-color: transparent;
    cursor: pointer;
    -webkit-appearance: button;
    -moz-appearance: button;
}
    
select {
    width: 100%;
    max-width: 100%;
    color: $text-color;
}
 
textarea {
    resize: none;
}
    
img {
    max-width: 100%;
    height: auto;
}

a {
    @extend .c1;
    @extend .trans3;
    &:hover {
        @extend .c2
    }
}

a,
a:hover,
a:active,
a:focus,
input,
input:hover,
input:focus,
input:active,
select,
textarea {
    text-decoration: none;
    outline: none !important;
}
    
h1,
h2,
h3,
h4,
h5,
h6 {
    padding: 0;
    margin: 0;
    -ms-word-wrap: break-word;
    word-wrap: break-word;
    font-weight: $bold;
    line-height: 1;
    @extend .c1;
    span {
        color: $c2;
    }
}

p:last-child {
    margin: 0;
}

h1 {
    font-size: 40px;
}
   
h2 {
    font-size: 34px;
}
  
h3 {
    font-size: 24px;
}
  
h4 {
    font-size: 18px;
}

h5 {
    font-size: 16px;
}

h6 {
    font-size: 14px;
}

table {
    width: 100%;
    max-width: 100%;
    th,
    td {
        border: 1px solid $text-color;
    }
}

pre {
    border: 1px solid;
    border-radius: 4px;
    font-size: 16px;
    line-height: 1.6;
    margin-bottom: 1.6em;
    max-width: 100%;
    overflow: auto;
    padding: 0.8em 1.6em;
}

code {
    background-color: rgba(27,31,35,.05);
    border-radius: 3px;
    font-size: 85%;
    margin: 0;
    padding: .2em .4em;
}

body {
    overflow-x: hidden !important;
    font-size: 18px;
    line-height: 1.625;
    color: $text-color;
}