/* ************************
   02.7: Widget
   ********************* */
.widget {
    background-color: #FBFBFB;
    padding: 40px 30px;
    font-size: 16px;
    &:not(:last-child) {
        margin-bottom: 50px;
    }
    .widget-title {
        margin-bottom: 30px;
        h4 {
            font-weight: $medium;
        }
    }
    ul {
        padding: 0;
        margin: 0;
        list-style: none;
    }
    &.widget_newsletter {
        .newsletter-content {
            p {
                margin-bottom: 25px;
            }
        }
        &.style--two {
            .newsletter-content {
                p {
                    font-size: 14px;
                }
                .theme-input-group button {
                    position: static;
                    width: 100%;
                    margin-top: 16px;
                    height: 52px;
                }
                .theme-input-group input {
                    background-color: $white;
                }
            }
        }
    }
    &.widget_contact {
        .contact-content {
            p {
                margin-bottom: 20px;
            }
            ul {
                li {
                    padding-left: 30px;
                    position: relative;
                    font-size: 14px;
                    line-height: 2;
                    &:not(:last-child) {
                        margin-bottom: 5px;
                    }
                    i {
                        position: absolute;
                        left: 0;
                        line-height: inherit;
                    }
                }
            }
        }
    }
    &.widget_service {
        .service-list {
            @extend .alegreya;
            li {
                line-height: 2;
                display: flex;
                align-items: center;
                a {
                    @extend .text-color;
                    &:hover {
                        @extend .c2;
                        i,
                        svg * {
                            @extend .c2;
                            fill: $c2;
                        }
                    }
                }
                i {
                    font-size: 18px;
                }
                img,
                svg,
                i {
                    margin-right: 10px;
                    @extend .trans3;
                }
                &:not(:last-child) {
                    margin-bottom: 24px;
                }
            }
        }
    }
    &.widget_recent_entries {
        .single-post {
            &:not(:last-child) {
                margin-bottom: 25px;
            }
            .post-image {
                margin-right: 20px;
            }
            .post-content {
                .posted-on {
                    display: block;
                    margin-bottom: 5px;
                }
                h5 {
                    line-height: 1.375;
                    font-size: 16px;
                    font-weight: 500;
                    a {
                        @extend .text-color;
                        &:hover {
                            @extend .c2;
                        }
                    }
                }
            }
        }
    }
    &.widget_search,
    &.widget_newsletter.style--two {
        @extend .search-form;
    }
    &.widget_tag_cloud {
        .tagcloud {
            margin: 0 -6px -6px 0;
            a {
                display: inline-block;
                padding: 5px 7px;
                background-color: $white;
                margin: 0 6px 6px 0;
                @extend .text-color;
                &:hover {
                    @extend .c2;
                }
            }
        }
    }
}