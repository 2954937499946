/* ************************
   03.4: Footer
   ********************* */
.footer {
    padding-top: 80px;
    padding-bottom: 30px;
    p {
        line-height: 1.75;   
    }
    p,
    a,
    li {
        color: #CFCFCF;
    }
    a:hover {
        @extend .c2;
    }
    .theme-input-group {
        input {
            &::placeholder {
                color: #FFFAF9 !important;
            }
        }
    }
    .title-border {
        span {
            &:first-child {
                width: 12px;
                @extend .c2-bg;
            }
            &:nth-child(2) {
                width: 20px;
                background-color: $white;
            }
        }
            
    } 
    .widget {
        margin-bottom: 30px;
        padding: 0;
        background-color: transparent;
        &:last-child {
            margin-bottom: 50px;
        }
        .widget-title {
            h3 {
                line-height: 1.25;
                color: $white;
                margin-bottom: 10px;
            }
        }
        .widget-logo,
        .widget-title {
            margin-bottom: 40px;
        }
    }
}