/* ************************
   02.1: Common Classes
   ********************* */
/* Transitions */
.trans2 {
    @include transition(.2s, linear);
}

.trans3 {
    @include transition(.3s, linear);
}

.trans4 {
    @include transition(.4s, linear);
}

/* Font Family */
h1,
h2,
h3,
h4,
h5,
h6,
.alegreya {
    font-family: $alegreya;
}

body,
.sourceSans {
    font-family: $sourceSans;
}

/* Text White */
.text-white {
    *,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        color: $white;
    }
} 

/* Border Radius */
.radius-5 {
    border-radius: 5px;
}

.radius-10 {
    border-radius: 10px;
}

/* Box Shadow */
.box-shadow {
    box-shadow: 0 5px 40px rgba(0, 0, 0, 0.05);
}

.no-shadow {
    box-shadow: none;
}

/* List Style */
.list-inline {
    margin-bottom: 0;
    li {
        display: inline-block;
    }
}

.list-check {
    margin-bottom: 0;
    padding: 0;
    list-style: none;
    li {
        font-size: 18px;
        line-height: 1.67;
        padding-left: 35px;
        position: relative;
        &:not(:last-child) {
            margin-bottom: 20px;
        }
        &:after {
            position: absolute;
            @include fa('\f00c');
            line-height: inherit;
            @extend .c2;
            left: 5px;
            top: -2px;
        }

        &:before {
            position: absolute;
            left: 0;
            top: 5px;
            content: '';
            width: 18px;
            height: 18px;
            border-radius: 50%;
            background-color: #FF988D;
            z-index: -1;
            opacity: .2;
        }
    }
}

.social_icon_list {
    padding: 0;
    margin: 0;
    list-style: none;
    line-height: 1;
    li {
        display: inline-block;
        &:not(:last-child) {
            margin-right: 25px;
        }
        a {
            font-size: 16px;
        }
    }
}

/* Owl Dots */
.owl-carousel {
    .owl-dots {
        position: absolute;
        right: 50px;
        top: 50%;
        transform: translateY(-50%);
        width: 30px;
    }
    button.owl-dot {
        display: inline-block;
        height: 3px;
        width: 16px;
        @extend .c1-bg;
        @extend .trans3;
        &.active {
            width: 28px;
            @extend .c2-bg;
        }
    }
}

/* Theme Input Group */
.theme-input-group {
    position: relative;
    width: 100%;
    input {
        width: 100%;
        height: 50px;
        border: none;
        padding-left: 15px;
        padding-right: 50px;
        color: $white;
        background-color: #334E95;
    }
    button {
        position: absolute; 
        top: 4px;
        right: 4px;
        height: calc(100% - 8px);
        padding: 9px 28px;
        line-height: 1;
        font-weight: $semi-bold;
        color: $white;
        @extend .trans2;
        @extend .c2-bg;
        &:hover {
            background-color: #fb9389;
        }
    }
}

/* Theme Input Style */
.theme-input-style {
    border: none;
    height: 52px;
    width: 100%;
    padding: 0 20px;
    background-color: #F9F9F9;
    font-size: 16px;
    @extend .trans3;
    &:focus {
        background-color: #fffcf6;
    }
}

textarea.theme-input-style {
    height: 200px;
    padding: 20px 20px;
}

/* Others */
.ov-hidden {
    overflow: hidden;
}
.ovx-hidden {
    overflow-x: hidden;
}

/* Pagination*/
.pagination {
    margin-top: 20px;
    li {
        margin: 0 10px;
        a,
        span {
            width: 50px;
            height: 50px;
            line-height: 1;
            position: relative;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            background-color: #f6f6f6;
            color: #5E5E5E;
            font-size: 24px;
            @extend .alegreya;
            &:hover,
            &.active {
                color: $white;
                @extend .c2-bg;
            }
        }
        &.nav-btn {
            a.disabled {
                cursor: not-allowed;
                opacity: .5;
            }
        }
    }
}

/* Blockquote */
blockquote {
    padding: 50px 20px;
    position: relative;
    font-size: 24px;
    line-height: 1.33;
    font-weight: $medium;
    @extend .c2;
    @extend .alegreya;
    cite {
        display: block;
        margin-top: 15px;
        font-size: 18px;
        line-height: 1.44;
        font-style: normal;
        font-weight: $regular;
        @extend .sourceSans;
        @extend .c1;
    }
    &:after {
        position: absolute;
        content: '“';
        left: 50%;
        top: 50%;
        font-size: 240px;
        font-weight: $bold;
        line-height: 41px;
        transform: translate(-50%, -50%);
        opacity: .1;
    }
}

/* Form Response */
.form-response {
    background-color: rgba($c1, .2);
    span {
        display: block;
        margin-top: 20px;
        margin-bottom: 30px;
        padding: 10px 15px;
    }  
} 

/* Border Effect */
.border-effect {
    position: relative;
    border: 2px solid #e7e7e7 !important;
    z-index: 1;
    &:before,
    &:after {
        position: absolute;
        content: '';
        display: block;
        height: 30px;
        width: 30px;
        transition: .4s ease-in;
        z-index: -1;
        @extend .c2-bo;
    }
    &:before {
        border-bottom: 2px solid;
        border-left: 2px solid;
        left: -2px;
        bottom: -2px;
    }
    &:after {
        border-top: 2px solid;
        border-right: 2px solid;
        right: -2px;
        top: -2px;
    }
    &:hover {
        &:after,
        &:before {
            width: calc(100% + 4px);
            height: calc(100% + 2px);
        }
    }
}

/* Title Border */
.title-border {
    font-size: 0;
    line-height: 1;
    span {
        display: inline-block;
        height: 3px;
        width: 12px;
        @extend .c2-bg;
        &:first-child {
            width: 24px;
            @extend .c1-bg;
        }
        &:not(:last-child) {
            margin-right: 3px;
        }
    }
}
/* Bg */
.light-bg {
    background-color: #FFFCF6;
}
/* Search Form */
.search-form {
    .theme-input-group {
        input {
            background-color: #F9F9F9;
            @extend .text-color;
            &::placeholder {
                color: #C9C9C9 !important;
            }
            &:focus {
                background-color: #fffcf6;
            }
        }
    }
}