/* ************************
   04.1: Service
   ********************* */
.single-service {
    padding: 60px 40px;
    background-color: $white;
    margin-bottom: 30px;
    @extend .border-effect;
    .icon {
        margin-bottom: 30px;
    }
    .content {
        h3 {
            margin-bottom: 20px;
        }
        p {
            line-height: 1.67;
        }
    }
}
