/* ************************
   02.4: Buttons
   ********************* */
.btn {
    display: inline-block;
    padding: 14px 30px;
    border-radius: 0;
    font-weight: $semi-bold;
    line-height: 1;
    font-size: 18px;
    position: relative;
    cursor: pointer;
    @extend .c1;
    @extend .sourceSans;
    @extend .trans3;
    @extend .border-effect;
    &:before,
    &:after {
        height: 10px;
        width: 10px;
    }
    &:focus,
    &.focus {
        box-shadow: none;
    }
}

/* Button Inline */
.btn-inline {
    display: inline-block;
    position: relative;
    font-size: 18px;
    font-weight: $semi-bold;
    @extend .c2;
    &:after {
        position: absolute;
        content: '';
        left: 0;
        bottom: 0;
        width: 0;
        height: 1.5px;
        @extend .trans3;
        @extend .c2-bg;
    }
    &:hover {
        &:after {
            width: 100%;
        }
    }
}