// Colors
$c1: #00217A;
$c2: #FB8579;
$text-color: #5E5E5E;
$white: #ffffff;
$black: #2c2c2c;

// Fonts
$alegreya: 'Alegreya', serif;
$sourceSans: 'Source Sans Pro', sans-serif;

// Font Weight
$thin: 100;
$extra-light: 200;
$light: 300;
$regular: 400;
$medium: 500;
$semi-bold: 600;
$bold: 700;
$extra-bold: 800;
$black: 900;