/* ************************
   03.3: Blog
   ********************* */
/* Single Blog */
.single-blog-item {
    position: relative;
    margin-bottom: 50px;
    .blog-image {
        img {
            display: block;
            width: 100%;
        }
    }
    .blog-content {
        margin-top: 30px;
        .posted {
            @extend .text-color;
        }
        h3 {
            line-height: 1.25;
            margin-bottom: 20px;
            margin-top: 10px;
        }
    }
}


/* Blog Details */
.blog-details {
    // Post Image
    .blog-details-image {
        margin-bottom: 40px;
    }

    // Post Meta
    .post-meta {
        margin-bottom: 20px;
        li {
            font-weight: $semi-bold;
            font-size: 16px;
            &:not(:last-child) {
                margin-right: 38px;
            }    
            a {
                @extend .text-color;
                font-weight: $regular;
                &:not(:last-child) {
                    &:after {
                        content: ',';
                    }
                }
                &:hover {
                    @extend .c2;
                }
            }
        }
    }

    // Post Title
    .post-title {
        margin-bottom: 40px;
        h2 {
            font-size: 40px;
            line-height: 1.23;
        }
    }

    // Blog Details Content
    .blog-details-content {
        margin-bottom: 60px;
        p {
            margin-bottom: 50px;
        }
        blockquote {
            margin-top: 30px;
            p {
                margin-bottom: 0;
            }
        }
        blockquote,
        h3,
        img {
            margin-bottom: 30px;
        }
        p:last-child {
            margin-bottom: 0;
        }
    }

    // Post Tags
    .post-tags {
        li {
            a {
                display: inline-block;
                font-size: 12px;
                padding: 5px 7px;
                background-color: #F7F7F7;
                @extend .text-color;
                &:hover {
                    @extend .c2;
                }
                &:not(:last-child) {
                    margin-right: 5px;
                }
            }
        }
    }

    // Post Pagination
    .single-post-pagination {
        max-width: 300px;
        .posted-on {
            @extend .text-color;
            line-height: 1;
            font-size: 12px;
        }
        h5 {
            line-height: 1.375;
            font-weight: $medium;
            @extend .text-color;
        }
        .pagination-image {
            margin-right: 20px;
            position: relative;
            z-index: 2;
            overflow: hidden;
            i {
                position: absolute;
                left: 5px;
                top: 0;
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 25px;
                color: $white;
                opacity: 0;
                visibility: hidden;
                z-index: 3;
                @extend .trans3;
            }
            &:after {
                position: absolute;
                content: '';
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                @extend .c2-bg;
                @extend .trans3;
                opacity: 0;
                visibility: hidden;
            }
        }
        a:hover {
            .pagination-image {
                &:after {
                    opacity: 1;
                    visibility: visible;
                }
                i {
                    left: 0;
                    opacity: 1;
                    visibility: visible;
                }
            }
        }
        &.next {
            .pagination-image {
                margin-right: 0;
                margin-left: 20px;
                i {
                    left: -5px;
                }
            }
        }
    }
    
    // Post Comments
    .post-comments-wrap {
        h4 {
            font-size: 20px;
        }
        .single-comment-wrapper {
            &:not(:last-child) {
                padding-bottom: 30px;
                border-bottom: 1px solid #f7f7f7;
            }
            .single-post-comment {
                margin-top: 30px;
                .comment-author-image {
                    margin-right: 20px;
                }
                .comment-content {
                    line-height: 1.5;
                    h5 {
                        margin-right: 30px;
                    }
                    span {
                        font-size: 12px;
                    }
                    p {
                        margin: 5px 0;
                        font-size: 14px;
                        line-height: 1.7;
                    }
                    .reply-btn {
                        line-height: 1;
                        font-size: 16px;
                        text-transform: capitalize;
                    }
                }
            }
            .post-comment-reply {
                margin-left: 120px;
                margin-top: 60px;
                @include mobileMd {
                    margin-left: 30px;
                }
                .post-comment-reply {
                    .post-comment-reply {
                        margin-left: 0;
                    }
                }
            }
        }
    }
    // Comments Form
    .comments-form {
        h4 {
            font-size: 20px;
            margin-bottom: 30px;
            text-transform: capitalize;
        }
        .theme-input-style {
            margin-bottom: 30px;
        }
    }
}
