/* ************************
   03.5: 404
   ********************* */
.content404 {
    h1 {
        font-size: 80px;
        line-height: 1.36;
        margin-bottom: 20px;
        @include mobileMd {
            font-size: 60px;
        }
    }
    a {
        @extend .c2;
    }
    > p {
        margin-bottom: 40px;
    }
    .search-form {
        p {
            font-weight: $semi-bold;
            margin-bottom: 20px;
        }
    }
}